var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "visitOwnerConfigList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          exportMethod: "new",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "拜访模版名称" },
                  model: {
                    value: _vm.searchParams.tempName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "tempName", $$v)
                    },
                    expression: "searchParams.tempName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "拜访类型", options: _vm.visitTypeList },
                  model: {
                    value: _vm.searchParams.visitType,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "visitType", $$v)
                    },
                    expression: "searchParams.visitType",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                scope.row.status === 0
                  ? _c("v-button", {
                      attrs: { text: "编辑", permission: "edit", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.edit(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.status === 0
                  ? _c("v-button", {
                      attrs: { text: "复制", permission: "add", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.copyConfig(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.status === 0
                  ? _c("v-button", {
                      attrs: {
                        text: "关闭",
                        permission: "close",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeState(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.status === 1
                  ? _c("v-button", {
                      attrs: {
                        text: "开启",
                        permission: "close",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.changeState(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }