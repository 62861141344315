<template>
  <div class="visitOwnerConfigList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      exportMethod="new"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add"  @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="拜访模版名称" v-model="searchParams.tempName"></v-input>
        <v-select label="拜访类型" v-model="searchParams.visitType" :options="visitTypeList"></v-select>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.status === 0" text="编辑" permission="edit" type="text" @click="edit(scope.row)"></v-button>
        <v-button v-if="scope.row.status === 0" text="复制" permission="add" type="text" @click="copyConfig(scope.row)"></v-button>
        <v-button v-if="scope.row.status === 0" text="关闭" permission="close" type="text" @click="changeState(scope.row)"></v-button>
        <v-button v-if="scope.row.status === 1" text="开启" permission="close" type="text" @click="changeState(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  uploadGuideURL
} from './api'
import { statusOps, statusMap,visitTypeList,visitTypeMap} from './map'
import { communityParams, tenantParams } from 'common/select2Params'

export default {
  name: 'visitOwnerConfigList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps,
      visitTypeList,
      communityParams,
      tenantParams,
      searchParams: {
        tempName: '',
        visitType: undefined,
      },
      headers: [
        {
          prop: 'tempName',
          label: '拜访模板名称'
        },
        {
          prop: 'visitType',
          label: '拜访类型',
          formatter (row) {
            return visitTypeMap[row.visitType]
          }
        },
        {
          prop: 'status',
          label: '模板状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'createTime',
          label: '创建时间'
        }

      ]
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'VisitOwnerConfigForm',
        query: {
          isEdit:false
        }
      })
    },
    edit (row) {
      this.$router.push({
        name: 'VisitOwnerConfigForm',
        query: {
          id: row.id,
          isEdit:true
        }
      })
    },
    copyConfig(row) {
      this.$router.push({
        name: 'VisitOwnerConfigForm',
        query: {
          id: row.id,
          isCopy: true
        }
      })
    },
    async changeState (row) {
      let msg = row.status === 0 ? '是否确认关闭' : '是否确认开启'
      let isOk = await this.$confirm(msg)

      let data = {
        id: row.id,
        status: row.status === 0 ? 1 : 0,
      }
      isOk && this.$axios({
        url: uploadGuideURL,
        method: 'post',
        data: data
      }).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    }
  }
}
</script>
