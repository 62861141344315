import { generateMapByOpts, mapHelper } from 'common/utils'

// 状态
const statusOps = [
  {
    text: '正常',
    value: 0
  },
  {
    text: '关闭',
    value: 1
  }
]

const statusMap = generateMapByOpts(statusOps)
// 拜访类型
const visitTypeList = [
  {
    text: '全部类型',
    value: undefined
  }, {
    text: '日常拜访',
    value: 0
  }, {
    text: '节日拜访',
    value: 1
  }, {
    text: '满意度拜访',
    value: 2
  }, {
    text: '客服拜访',
    value: 3
  }
]
const visitTypeMap = generateMapByOpts(visitTypeList)
// 拜访方式
const visitWayList = [
  {
    text: '电话',
    value: '1'
  }, {
    text: '线上',
    value: '2'
  }, {
    text: '线下/上门',
    value: '3'
  }, {
    text: '其他',
    value: '4'
  }
]
const visitWayMap = generateMapByOpts(visitWayList)

const visitTypeOps = [
  {
    text: '日常拜访',
    value: 0
  }, {
    text: '节日拜访',
    value: 1
  }, {
    text: '满意度拜访',
    value: 2
  }, {
    text: '客服拜访',
    value: 3
  }
]

export {
  statusOps,
  statusMap,
  visitTypeList,
  visitTypeMap,
  visitWayList,
  visitWayMap,
  visitTypeOps
}
